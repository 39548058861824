import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 0;
`;

export const ProgessbarWrapper = styled.span`
  background: grey;
  height: 8px;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
`;

export const Progessbar = styled.span`
  height: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #000;
  border-radius: 10%;
`;
