import React from 'react';
import { deliverFeeGer, deliverFreeTotal, isStickerProduct } from '../../../config';
import useCart from '../../../hooks/useCart';
import formatCurrency from '../../../utils/formatCurrency';
import { Wrapper, ProgessbarWrapper, Progessbar } from './styles';

export default function DeliveryNote() {
  const { lineItems } = useCart();

  const merchArticle = lineItems.filter(({ variant: { product: { id } } }) => !isStickerProduct(id))
  let merchTotal = 0
  merchArticle.forEach(({ variant: { price }, quantity }) => {
    merchTotal = merchTotal + price.amount * quantity
  })

  const deliveryFreeOffset = deliverFreeTotal - merchTotal;
  const deliveryFreeOffsetPercent = merchTotal / deliverFreeTotal;
  const innerBarWidth =
    deliveryFreeOffset > 0 ? deliveryFreeOffsetPercent * 100 : 100;

  return (
    <Wrapper>
      <span style={{ fontSize: '12px', marginBottom: '8px' }}>
        Nur noch
        <b style={{ padding: '0 4px', fontSize: '14px' }}>
          {formatCurrency(deliveryFreeOffset > 0 ? deliveryFreeOffset : 0)}
        </b>
        bis zum kostenlosen Versand deiner Fanartikel (exklusive Sticker)
      </span>
      <ProgessbarWrapper>
        <Progessbar style={{ width: `${innerBarWidth}%` }}></Progessbar>
      </ProgessbarWrapper>
      <p style={{ fontSize: '12px', textAlign: 'right' }}>
        Versandkosten Deutschland: {formatCurrency(deliverFeeGer)}
      </p>
    </Wrapper>
  );
}
