import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/styles';

export const VariantImage = styled.img`
  height: 50px;
  width: auto;

  @media (min-width: ${breakpoints.l}px) {
    height: 90px;
  }
`