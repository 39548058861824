import React, { useContext } from 'react';

import formatCurrency from '../../../utils/formatCurrency';
import StoreContext from '../../../context/StoreContext';
import { productRequiresStickerId } from '../../../config';
import { VariantImage } from './style';

const LineItem = props => {
  const { line_item } = props;
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = line_item.variant.image ? (
    <VariantImage
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
    />
  ) : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id);
  };

  const stickerNumber = line_item.customAttributes.find(
    ({ key }) => key === 'stickerNumber'
  )?.value;

  const totalPrice = formatCurrency(
    line_item.variant.price.amount * line_item.quantity
  );

  return (
    <div className="row" style={{flexWrap: 'wrap'}}>
      <div style={{flex: 2}}>{variantImage}</div>
      <div style={{minWidth: '200px'}}>
        <b>{line_item.title}</b>
        {productRequiresStickerId(
          `Shopify__Product__${line_item.variant.product.id}`
        ) && (
          <>
            <br />
            Stickernummer: {stickerNumber}
          </>
        )}
        <br />
        {line_item.variant.title !== 'Default Title' && line_item.variant.title}
      </div>
      <div style={{flex: 2}}>{line_item.quantity} Stk.</div>
      <div style={{flex: 2}}>
        <b>{totalPrice}</b>
      </div>
      <div style={{flex: 1}}>
        <button
          onClick={handleRemove}
          className="alt"
          data-testid="qa-remove-line-item"
        >
          x
        </button>
      </div>
    </div>
  );
};

export default LineItem;
