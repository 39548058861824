import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import LineItem from './LineItem';
import useCart from '../../hooks/useCart';
import formatCurrency from '../../utils/formatCurrency';
import {
  deliverFeeGer,
  deliverFeeStickerGer,
  productRequiresConsent,
} from '../../config';
import Alert from '../Alert';

import './style.css';
import DeliveryNote from './DeliveryNote';

const Cart = () => {
  const checkout = useCart();
  const [eveChecked, setEveChecked] = useState(false);
  const [stickerNumberChecked, setStickerNumberChecked] = useState(false);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  /**
   * Check if cart contains
   * any products that need a consent file -
   * we'll show an additional confirmation checkbox
   * if that's the case.
   */
  const productIds = checkout.lineItems.map(({ variant: { product } }) => product.id);
  const cartRequiresConsent = productIds.some(shopifyId => 
    productRequiresConsent(shopifyId)
  );

  const line_items = (
    <TransitionGroup>
      {checkout.lineItems.map(line_item => (
        <CSSTransition
          key={line_item.id.toString()}
          timeout={500}
          classNames="list-item"
        >
          <LineItem line_item={line_item} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );

  return (
    <div>
      {line_items.length === 0 && <div>Dein Warenkorb ist leer.</div>}
      <div className="cart-table">{line_items}</div>
      <Alert>
        Die <b>Versandkosten</b> belaufen sich pro Bestellung auf{' '}
        {formatCurrency(deliverFeeGer)}. Sollten Sticker Teil der Bestellung
        sein, wird eine zusätzliche Bearbeitungsgebühr von{' '}
        {formatCurrency(deliverFeeStickerGer)} erhoben. Sollten nur Sticker
        bestellt werden, fällt nur die Bearbeitungsgebühr{' '}
        {formatCurrency(deliverFeeStickerGer)} an
      </Alert>
      <div className="cart-checkout">
        <div />
        <div style={{ maxWidth: '400px' }}>
          <h2>Total</h2>
          <p>
            {checkout.totalPrice && formatCurrency(checkout.totalPrice.amount)} zzgl.
            Versand
          </p>
          <DeliveryNote />
          <br />
          <div style={{ marginBottom: '1rem' }}>
            <input
              type="checkbox"
              id="stickernumber_confirm"
              value={stickerNumberChecked}
              onChange={() => setStickerNumberChecked(!stickerNumberChecked)}
            />
            <label htmlFor="stickernumber_confirm">
              Stickernummer überprüft - Achtung!
              <br />
              Hast du die richtige(n) Stickernummer(n) eingetippt? Bitte
              überprüfe die von dir angegebene Stickernummer(n) oben noch
              einmal.
            </label>
          </div>
          {cartRequiresConsent && (
            <div style={{ marginBottom: '1rem' }}>
              <input
                type="checkbox"
                id="eve_confirm"
                value={eveChecked}
                onChange={() => setEveChecked(!eveChecked)}
              />
              <label htmlFor="eve_confirm">
                Ich bestätige, dass ich das Einverständnis der auf den
                bestellten Produkten (außer Sticker) abgebildeten Personen
                eingeholt habe.
              </label>
            </div>
          )}
          <button
            onClick={handleCheckout}
            disabled={
              !cartRequiresConsent
                ? !stickerNumberChecked
                : !stickerNumberChecked || !eveChecked
            }
            style={{ minWidth: '200px' }}
          >
            Zur Kasse
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
