import React from 'react';

import Cart from '../components/Cart';
import { Container } from '../utils/styles';

const CartPage = () => (
  <Container>
    <Cart />
  </Container>
);

export default CartPage;
